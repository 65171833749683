const environment = {
  LOCAL: {
    BACK_END_URL: "http://127.0.0.1:5000",
    FFM_URL: "https://api.stg01e.gcp.ford.com/",
    FDC_URL: "https://qa.electriphi.dev/",
    DEPOT_TYPE: "TEST",
    SIMULATEDDEPOT: true,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "FDC QA depot 1",
        depotId: "91ae5f46-4270-4a30-8372-5261254a9089",
      },
      {
        depotName: "Central Server Testing Depot",
        depotId: "b530125a-56e2-4d93-a43e-35164a0e53e5",
      },
      {
        depotName: "Provisioning - Next Gen",
        depotId: null,
      },
    ],
    ENABLE_FDC_AUTH_BYPASS: false,
    GLOBAL_OCPP_DEPOT: {
      companyId: "b0d23255-d82f-4cce-8412-b7afd7d64bbb",
      companyName: "Depot Charging Test Customer",
      depotId: null,
      depotName: "Provisioning - Next Gen",
    },
    VITE_AZURE_REDIRECT_URI: "http://localhost:3000",
    VITE_AZURE_TENANT_ID: "c990bb7a-51f4-439b-bd36-9c07fb1041c0",
    VITE_AZURE_CLIENT_ID: "d3f3b9b1-41af-42bc-89b3-e97e694b9048",
    GENERIC_CHARGER_SOLD_AS_ID: "38e3f2f2-2ada-462b-9912-762b5cd8dc24",
  },

  DEV: {
    BACK_END_URL: "https://api.us1.electriphi.dev/ops-console",
    FFM_URL: "https://fleetaccount-stage.ford.com/",
    FDC_URL: "https://qa.electriphi.dev/",
    DEPOT_TYPE: "TEST",
    SIMULATEDDEPOT: true,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "FDC QA Depot-1",
        depotId: "91ae5f46-4270-4a30-8372-5261254a9089",
      },
      {
        depotName: "Central Server Testing Depot",
        depotId: "b530125a-56e2-4d93-a43e-35164a0e53e5",
      },
      {
        depotName: "Provisioning - Next Gen",
        depotId: null,
      },
    ],
    ENABLE_FDC_AUTH_BYPASS: false,
    GLOBAL_OCPP_DEPOT: {
      companyId: "b0d23255-d82f-4cce-8412-b7afd7d64bbb",
      companyName: "Depot Charging Test Customer",
      depotId: null,
      depotName: "Provisioning - Next Gen",
    },
    VITE_AZURE_REDIRECT_URI: "https://operations-develop.electriphi.dev",
    VITE_AZURE_TENANT_ID: "c990bb7a-51f4-439b-bd36-9c07fb1041c0",
    VITE_AZURE_CLIENT_ID: "3a72b21c-4a6b-4a86-919a-f241c2cb5574",
    GENERIC_CHARGER_SOLD_AS_ID: "38e3f2f2-2ada-462b-9912-762b5cd8dc24",
  },

  STAGE: {
    BACK_END_URL: "https://api.us1.staging.electriphi.dev/ops-console/",
    FFM_URL: "https://fleetaccount-stage.ford.com/",
    FDC_URL: "https://stage.electriphi.dev/",
    DEPOT_TYPE: "TEST",
    SIMULATEDDEPOT: true,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "FDC QA Depot-1",
        depotId: "91ae5f46-4270-4a30-8372-5261254a9089",
      },
      {
        depotName: "Central Server Testing Depot",
        depotId: "b530125a-56e2-4d93-a43e-35164a0e53e5",
      },
      {
        depotName: "Provisioning - Next Gen",
        depotId: null,
      },
    ],
    ENABLE_FDC_AUTH_BYPASS: false,
    GLOBAL_OCPP_DEPOT: {
      companyId: "b0d23255-d82f-4cce-8412-b7afd7d64bbb",
      companyName: "Depot Charging Test Customer",
      depotId: null,
      depotName: "Provisioning - Next Gen",
    },
    VITE_AZURE_REDIRECT_URI: "https://operations-stage.electriphi.dev/",
    VITE_AZURE_TENANT_ID: "c990bb7a-51f4-439b-bd36-9c07fb1041c0",
    VITE_AZURE_CLIENT_ID: "a801171f-d039-4175-af6c-dc1605840096",
    GENERIC_CHARGER_SOLD_AS_ID: "38e3f2f2-2ada-462b-9912-762b5cd8dc24",
  },

  PROD: {
    BACK_END_URL: "https://api.us1.services.electriphi.io/ops-console/",
    FFM_URL: "https://fleetaccount.ford.com/",
    FDC_URL: "https://fordprocharging.electriphi.io/",
    DEPOT_TYPE: "CUSTOMER",
    SIMULATEDDEPOT: false,
    PRE_PROVISIONING_DEPOTS: [
      {
        depotName: "Provisioning - Ford Pro AC 19.5 kW chargers",
        depotId: "55b102f8-4176-43d6-837c-6ec47014987c",
      },
      {
        depotName: "Provisioning - Ford Pro DC Chargers",
        depotId: "31330d1a-5bfe-4ad7-9d81-f3ac0e5b1ca7",
      },
      {
        depotName: "Provisioning - Ford Pro AC 11.5 kW chargers",
        depotId: "c70b45ab-6411-483f-bce8-f4b1ecf756f0",
      },
      {
        depotName: "Provisioning - Next Gen",
        depotId: null,
      },
    ],
    // In prod the ENABLE_FDC_AUTH_BYPASS should always be false
    ENABLE_FDC_AUTH_BYPASS: false,
    GLOBAL_OCPP_DEPOT: {
      companyId: "04c03cb1-9d8d-4b25-a48a-1477ffb7b2c4",
      companyName: "Internal - Depot Charging Beta Test Customer",
      depotId: null,
      depotName: "Provisioning - Next Gen",
    },
    VITE_AZURE_REDIRECT_URI: "https://operations.electriphi.dev",
    VITE_AZURE_TENANT_ID: "c990bb7a-51f4-439b-bd36-9c07fb1041c0",
    VITE_AZURE_CLIENT_ID: "de9d012c-ba20-46d5-914c-9c010c2daa54",
    GENERIC_CHARGER_SOLD_AS_ID: "ddaca0a7-fb6f-49ab-b5d3-ea9489ca6765",
  },
};

export default environment;
